import React from 'react';
import '../ModalEditWindow.css';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
    getSingleDiscounts,
    sendRequest,
    deleteDiscountModal,
    updateDiscounts,
    calculateDiscounts,
} from '../../../actions/discounts';
import { reduxForm, Form, Field, change, reset } from 'redux-form';
import onSubmit from './../onSubmit';
import { withTranslation } from 'react-i18next';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import FaceIcon from '@material-ui/icons/Face';
import { DatePicker } from '@material-ui/pickers';

import {
    Accordion,
    AccordionSummary,
    AccordionDetails,
} from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { closeRequestModal } from '../../../actions/modals';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Skeleton from '@material-ui/lab/Skeleton';
import UsersService from '../../../services/users.service';
import MenuItem from '@material-ui/core/MenuItem';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import CircularProgress from '@material-ui/core/CircularProgress';
import { editUser, editUserSales } from '../../../actions/getUsers';
import TimerOffIcon from '@material-ui/icons/TimerOff';
import DialogActions from '@material-ui/core/DialogActions';
import store from '../../../store';
import { Chip, Collapse, Stack } from '@mui/material';
import FormHelperText from '@material-ui/core/FormHelperText';
import DiscountsOptions from '../modalFormComponents/DiscountsOptions';
import { formatDateWithoutTimezone, calculateDiscountEndDate } from '../../../actions/date';
import { getCurrentCurrency } from '../../../actions/currencies';

const schema_d = {
    1: 'sales.invalid',
    2: 'sales.children_teacher',
    3: 'sales.discounts_school',
    4: 'sales.custom',
    5: 'sales.refer_discounts',
    6: 'sales.family_discounts',
};

const reverseSchema = {
    special: 1,
    coach: 2,
    feedback: 3,
    arbitrary: 4,
    family: 6,
    friend: 5,
};

const state = {
    procent: 0,
    dicsount_state: '',
    data: [],
    loading: true,
    selected: '-1',
    students: [],
    search: false,
    email: '',
    last_name: '',
    amountState: 'percent',
    currency: '',
};

class EditDiscountsModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = state;
    }
    componentDidMount() {
        const currency = getCurrentCurrency();
        this.setState({ currency });
        this.getData();
    }

    async getProfile() {
        if (!this.state.email && !this.state.last_name) {
            console.log('no email!');
            return;
        }
        this.setState({ search: true });
        const params = {
            email: this.state.email,
            last_name: this.state.last_name,
        };

        if (!this.state.email) delete params.email;
        if (!this.state.last_name) delete params.last_name;

        const res = await UsersService.GetProfileByEmail(params);

        if (res && res.list.length) {
            const collision = res.list.findIndex(
                (el) => el.id === this.props.data.id
            );
            for (let item of this.props.discounts.list.filter(Boolean)) {
                const col = res.list.findIndex((el) => el.id === item.id);
                if (col !== -1) delete res.list[col];
            }
            if (collision !== -1) delete res.list[collision];
        }
        this.setState({
            students: res && res.list.length ? res.list : [],
            selected: '-1',
            search: false,
        });
    }

    async getData() {
        await this.props.getSingleDiscounts(false, { id: this.props.data.id });
        this.setState({ loading: false });
    }

    handleTextField = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    };
    handleClear = () => {
        this.setState({ selected: '-1', students: [] });
    };
    renderTextField = ({ input, meta: { touched, error }, ...custom }) => (
        <TextField {...input} {...custom} />
    );
    handleProcentRender = ({ input, meta: { touched, error }, ...custom }) => {
        const { t } = this.props;

        return (
            <TextField
                {...input}
                {...custom}
                error={touched && !!error}
                helperText={!!error && t(`error.${error}`)}
            />
        );
    };
    bigSaleUpdate = ({ input, meta: { touched, error }, ...custom }) => {
        const { t } = this.props;
        let statusesArr = [
            { label: t('sales.choose'), value: 0 },
            { label: t('sales.invalid'), value: 1 },
            { label: t('sales.children_teacher'), value: 2 },
            { label: t('sales.discounts_school'), value: 3 },
            { label: t('sales.custom'), value: 4 },
            { label: t('sales.refer_discounts'), value: 5 },
            { label: t('sales.family_discounts'), value: 6 },
        ];
        if (this.props.data.user_status !== 0) delete statusesArr[5];
        return (
            <FormControl
                variant={custom.variant || 'outlined'}
                {...custom.variant}
                error={touched && !!error}
            >
                <Select
                    {...input}
                    {...custom}
                    native
                    inputProps={{
                        name: input.name,
                    }}
                >
                    {statusesArr.filter(Boolean).map((item, key) => (
                        <option
                            key={key}
                            value={item.value}
                            disabled={item.disbled}
                        >
                            {item.label}
                        </option>
                    ))}
                </Select>
                <Collapse in={!!error}>
                    <FormHelperText>{t(`error.${error}`)}</FormHelperText>
                </Collapse>
            </FormControl>
        );
    };

    handleDelete = async (id, discount_group) => {
        const res = this.props.discounts.list.filter(
            (item) => item && item.discount_group !== 6 && item.id !== id
        );

        let deleteData = {
            discount_id: id,
        };
        if (discount_group >= 5) {
            deleteData = {
                ...deleteData,
                d_type: discount_group === 5 ? 'referer' : 'family',
            };
        }
        await this.props.deleteDiscountModal(
            false,
            deleteData,
            this.props.data.id
        );
        this.setState({ ...state });
        store.dispatch(reset('EditDiscountsModal'));
        await this.getData();
    };
    handleDisChoose = (event) => {
        this.setState({ dicsount_state: event.target.value });
    };

    renderSelectField = ({ input, meta: { touched, error }, ...custom }) => {
        const { t } = this.props;
        if (!input.value) input.value = '-1';
        return (
            <Select
                {...input}
                {...custom}
                error={touched && !!error}
                inputProps={{
                    name: input.name,
                }}
                style={{ width: '100%' }}
            >
                <MenuItem value="-1">{t('sales.choose_student')}</MenuItem>
                {this.state.students.map((item, key) => {
                    return (
                        <MenuItem key={key} value={item.id}>
                            {`${item.first_name} ${item.last_name}`}
                        </MenuItem>
                    );
                })}
            </Select>
        );
    };

    renderSelectFieldRefer = ({
        input,
        meta: { touched, error },
        ...custom
    }) => {
        const { t } = this.props;
        if (!input.value) input.value = '-1';
        return (
            <Select
                {...input}
                {...custom}
                error={touched && !!error}
                inputProps={{
                    name: input.name,
                }}
                style={{ width: '100%' }}
            >
                <MenuItem value="-1">{t('sales.choose')}</MenuItem>
                <MenuItem value={1}>{`5%  |  5%`}</MenuItem>
                <MenuItem value={2}>{`10%  |  0%`}</MenuItem>
            </Select>
        );
    };

    renderDatePicker = ({
        input: { onBlur, value, ...inputProps },
        meta: { touched, error },
        ...custom
    }) => {
        const { t } = this.props;
        const onChange = (dates) => {
            Date.parse(dates)
                ? inputProps.onChange(dates.toISOString())
                : inputProps.onChange(null);
        };
        return (
            <DatePicker
                {...inputProps}
                {...custom}
                autoOk
                error={touched && !!error}
                helperText={!!error && t(`error.${error}`)}
                allowKeyboardControl={false}
                animateYearScrolling
                inputVariant="outlined"
                disablePast
                format="dd.MM.yyyy"
                value={value ? new Date(value) : null}
                onChange={onChange}
            />
        );
    };

    createDate = (created) => {
        const date = new Date(created);
        return isNaN(date) ? new Date(JSON.parse(created)) : date;
    };

    pad = (number) => (number < 10 ? `0${number}` : number);

    isExpired = (item) =>
        item.discount_group === 4 &&
        new Date().getTime() >=
            new Date(item.created).getTime() + 86400000 * item.period_days;

    render() {
        const { t } = this.props;

        const formData =
            this.props.editDiscounts && this.props.editDiscounts.values;
        const totalPrice = calculateDiscounts(
            this.props.discounts.price,
            this.props.discounts.list
        );
        return (
            <Form onSubmit={this.props.handleSubmit}>
                {!this.props.discounts && this.state.loading ? (
                    <Skeleton variant="rect" width={'100%'} height={50} />
                ) : null}
                <Collapse in={!!this.props.discounts.price}>
                    <Typography variant={'caption'}>
                        {`${t('payments.subscription')}: ${
                            this.props.discounts.price
                        } ${this.state.currency}`}
                        {this.props.discounts.discountAmount &&
                        this.props.discounts.price
                            ? `, ${t(
                                  'payments.abonement with discount'
                              )}: ${Math.round(
                                  this.props.discounts.price -
                                      this.props.discounts.discountAmount
                              )} ${this.state.currency}`
                            : null}
                    </Typography>
                </Collapse>

                {this.props.discounts &&
                    this.props.discounts.list.map((item, key) => {
                        const date = this.createDate(item.created);
                        const created_date = formatDateWithoutTimezone(item.updated, true);
                        const discountEndDate = calculateDiscountEndDate(item.updated, item.days, true);
                        const expired = this.isExpired(item);
                        const schema_classes = {
                            5: 'referer',
                            6: 'family',
                        };
                        const discountIndex = reverseSchema[item.kind];
                        const discountName = schema_d[discountIndex];
                        const family = this.props.discounts.family.sort(
                            (a, b) => {
                                return (
                                    new Date(a.created) - new Date(b.created)
                                );
                            }
                        );
                        return (
                            <div
                                className={
                                    expired
                                        ? 'row select_full expierd animated fadeIn'
                                        : 'row select_full animated fadeIn'
                                }
                                key={key}
                            >
                                <Accordion
                                    className={`${schema_classes[discountIndex]} discounts_accordion`}
                                >
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls={item.user_id}
                                    >
                                        <Typography>
                                            {t(discountName)}{' '}
                                            {expired ? <TimerOffIcon /> : null}
                                            {(discountIndex === 4 &&
                                                item.amount_rate &&
                                                ` | ${item.amount_rate}%`) ||
                                                (item.amount_fix &&
                                                    ` | ${item.amount_fix}`) ||
                                                null}
                                            {discountIndex >= 5 ? (
                                                <FaceIcon />
                                            ) : null}
                                        </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography component="div">
                                            <DiscountsOptions
                                                field={item.amount_rate}
                                                label={'table.d_amount_rate'}
                                            />
                                            <DiscountsOptions
                                                field={item.amount_fix}
                                                label={'table.d_amount_fix'}
                                            />
                                            <DiscountsOptions
                                                field={item.creator_id}
                                                label={'table.creator_id'}
                                            />
                                            <DiscountsOptions
                                                field={item.description}
                                                label={'modals.description'}
                                            />
                                            <DiscountsOptions
                                                field={item.days}
                                                label={'sales.days'}
                                            />
                                            {/*<DiscountsOptions*/}
                                            {/*    field={item.days}*/}
                                            {/*    label={'sales.d_amount_rate'}*/}
                                            {/*/>*/}
                                            {/*{discountIndex < 5 ? (*/}
                                            <DiscountsOptions
                                                field={created_date}
                                                label={'modals.create_date'}
                                            />
                                            <DiscountsOptions
                                                field={discountEndDate ? discountEndDate : t('modals.indefinitely')}
                                                label={'modals.discountEndDate'}
                                            />
                                            <DiscountsOptions
                                                field={family}
                                                label={'modals.familyMember'}
                                                family={family.length}
                                                id={this.props.data.id}
                                            />
                                            {/*// ) : null*/}
                                            {!expired ? (
                                                <Button
                                                    style={{
                                                        marginTop: '20px',
                                                    }}
                                                    onClick={() =>
                                                        this.handleDelete(
                                                            item.id,
                                                            discountIndex
                                                        )
                                                    }
                                                    variant="contained"
                                                    color="secondary"
                                                >
                                                    {t('sales.delete')}
                                                </Button>
                                            ) : null}
                                        </Typography>
                                    </AccordionDetails>
                                </Accordion>
                            </div>
                        );
                    })}

                <div className="row select_full">
                    <Field
                        name={'discounts_group'}
                        component={this.bigSaleUpdate}
                        value={this.state.dicsount_state}
                        onChange={this.handleDisChoose}
                        disabled={
                            totalPrice && totalPrice.availableDiscount === 0
                        }
                    />
                </div>
                {this.state.dicsount_state &&
                +this.state.dicsount_state <= 4 ? (
                    <div className="row item full">
                        <Field
                            multiline
                            name="description"
                            variant="outlined"
                            component={this.renderTextField}
                            label={t('modals.description')}
                            required
                        />
                    </div>
                ) : null}

                {this.state.dicsount_state === '4' ? (
                    <>
                        <Stack
                            direction={'row'}
                            spacing={2}
                            style={{ marginBottom: '10px' }}
                        >
                            <div>
                                <Button
                                    variant={
                                        (this.state.amountState === 'percent' &&
                                            'contained') ||
                                        'outlined'
                                    }
                                    onClick={() => {
                                        this.setState({ amountState: '' });
                                        store.dispatch(
                                            change(
                                                'EditDiscountsModal',
                                                'amount_fix',
                                                ''
                                            )
                                        );

                                        setTimeout(() => {
                                            this.setState({
                                                amountState: 'percent',
                                            });
                                        }, 200);
                                    }}
                                >
                                    {t('table.d_amount_rate')}
                                </Button>
                            </div>
                            <div>
                                <Button
                                    variant={
                                        (this.state.amountState === 'number' &&
                                            'contained') ||
                                        'outlined'
                                    }
                                    onClick={() => {
                                        this.setState({ amountState: '' });
                                        store.dispatch(
                                            change(
                                                'EditDiscountsModal',
                                                'amount_rate',
                                                ''
                                            )
                                        );
                                        setTimeout(() => {
                                            this.setState({
                                                amountState: 'number',
                                            });
                                        }, 200);
                                    }}
                                >
                                    {t('modals.ammount')}
                                </Button>
                            </div>
                        </Stack>
                        <Collapse in={this.state.amountState === 'percent'}>
                            {this.state.amountState === 'percent' && (
                                <div className="row item full">
                                    <Field
                                        name="amount_rate"
                                        component={this.handleProcentRender}
                                        label={t('sales.procent')}
                                        type="number"
                                        variant="outlined"
                                        required
                                    />
                                </div>
                            )}
                        </Collapse>
                        <Collapse in={this.state.amountState === 'number'}>
                            {this.state.amountState === 'number' && (
                                <div className="row item full">
                                    <Field
                                        name="amount_fix"
                                        component={this.handleProcentRender}
                                        label={t('table.d_amount_fix')}
                                        type="number"
                                        variant="outlined"
                                        required
                                    />
                                </div>
                            )}
                        </Collapse>
                    </>
                ) : null}

                {this.state.dicsount_state === '4' ? (
                    <div className="row item full">
                        <Field
                            name="days"
                            label={t('sales.days')}
                            variant="outlined"
                            component={this.renderDatePicker}
                            required
                        />
                    </div>
                ) : null}

                {this.state.dicsount_state === '5' ||
                this.state.dicsount_state === '6' ? (
                    <>
                        {this.state.students.length ? (
                            <>
                                <div className="select_student sales">
                                    <Field
                                        name="child"
                                        variant="outlined"
                                        component={this.renderSelectField}
                                        required
                                    />
                                    <IconButton
                                        aria-label="delete"
                                        className="clearStudents"
                                        onClick={this.handleClear}
                                    >
                                        <DeleteIcon fontSize="small" />
                                    </IconButton>
                                </div>
                                <br />
                                {this.state.dicsount_state === '5' ? (
                                    <div className="row item full">
                                        <Field
                                            name="referer_type"
                                            variant="outlined"
                                            component={
                                                this.renderSelectFieldRefer
                                            }
                                            required
                                        />
                                    </div>
                                ) : null}
                            </>
                        ) : (
                            <>
                                <div className="row item full">
                                    <TextField
                                        disabled={this.state.search}
                                        type="text"
                                        variant="outlined"
                                        label={t('modals.email')}
                                        name="email"
                                        value={this.state.email}
                                        onChange={this.handleTextField}
                                    />
                                </div>
                                <div className="row item full">
                                    <TextField
                                        disabled={this.state.search}
                                        type="text"
                                        variant="outlined"
                                        label={t('modals.last_name')}
                                        name="last_name"
                                        value={this.state.last_name}
                                        onChange={this.handleTextField}
                                    />
                                </div>
                                <Button
                                    variant="contained"
                                    className="greenButton"
                                    onClick={() => this.getProfile()}
                                    disabled={this.state.search}
                                    style={{
                                        width: '100%',
                                        margin: 0,
                                        marginTop: '15px',
                                    }}
                                >
                                    {this.state.search ? (
                                        <CircularProgress
                                            color="inherit"
                                            size={20}
                                        />
                                    ) : (
                                        t('relocate.search')
                                    )}
                                </Button>
                                <br />
                            </>
                        )}
                    </>
                ) : null}

                <DialogActions>
                    <Button
                        className="request-button orangeButton"
                        onClick={this.props.closeRequestModal}
                    >
                        {t('buttons.cancel')}
                    </Button>
                    <Button
                        variant="contained"
                        className="request-button greenButton"
                        type="submit"
                        disabled={
                            this.props.loading ||
                            !this.state.dicsount_state ||
                            !+this.state.dicsount_state ||
                            (this.state.dicsount_state >= '5' &&
                                !(
                                    !!this.state.students.length &&
                                    formData &&
                                    formData.child &&
                                    !(formData.child === '-1')
                                ))
                        }
                        endIcon={
                            this.props.loading ? (
                                <CircularProgress size={10} />
                            ) : null
                        }
                    >
                        {t('buttons.apply')}
                    </Button>
                </DialogActions>
            </Form>
        );
    }
}

const validate = (values, props) => {
    const errors = {};
    values.user_id = props.data.id;
    const discountGroup = Number(values.discounts_group);
    if (values.days) {
        const days = Math.ceil(
            (new Date(values.days).getTime() - new Date().getTime()) /
                (1000 * 60 * 60 * 24)
        );
        if (days < 1) {
            errors.days = 'cannot use current day';
        }
    }
    if (!values.amount_rate) {
        errors.amount_rate = '';
    }
    if (+values.amount_rate > 100 || +values.amount_rate < 1) {
        errors.amount_rate =
            'Percent can be only larger than 100 and not less than 1';
    }
    if (+values.amount_fix <= 0) {
        errors.amount_fix = 'cannot be less than a zero';
    }

    if (values.amount_fix || values.amount_rate) {
        const count = calculateDiscounts(props.discounts.price, [
            ...props.discounts.list,
            { amount_rate: values.amount_rate, amount_fix: values.amount_fix },
        ]);
        if (count && count.availableDiscount === -1) {
            errors.amount_fix = `Fixed discount cannot be higher than half of abonement`;
            errors.amount_rate = `Fixed discount cannot be higher than half of abonement`;
        }
    }
    if (discountGroup === 1 || discountGroup === 3) {
        const schema = {
            1: 50,
            3: 5,
        };
        const count = calculateDiscounts(props.discounts.price, [
            ...props.discounts.list,
            { amount_rate: schema[discountGroup] },
        ]);
        if (count && count.availableDiscount === -1) {
            errors.discounts_group = `Fixed discount cannot be higher than half of abonement`;
        }
    }
    if (discountGroup === 4 && !values.days) {
        errors.days = 'fill_field';
    }
    if (discountGroup >= 5 && (values.child === '-1' || !values.child)) {
        errors.child = 'enter all data';
    }
    if (
        discountGroup === 5 &&
        (values.referer_type === '-1' || !values.referer_type)
    ) {
        errors.referer_type = 'enter all data';
    }
    if (discountGroup === 6 && values.referer_type) {
        values.referer_type = '';
    }
    return errors;
};

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            getSingleDiscounts,
            deleteDiscountModal,
            closeRequestModal,
            updateDiscounts,
            editUser,
            editUserSales,
        },
        dispatch
    );

const mapStateToProps = (state) => ({
    user: state.user,
    discounts: state.discounts,
    editDiscounts: state.form.EditDiscountsModal,
    loading: state.discounts && state.discounts.loading,
});

const withForm = reduxForm({
    form: 'EditDiscountsModal',
    updateUnregisteredFields: true,
    onSubmit,
    validate,
    onAccept: sendRequest,
})(EditDiscountsModal);

export { schema_d, reverseSchema };

export default withTranslation()(
    connect(mapStateToProps, mapDispatchToProps)(withForm)
);
