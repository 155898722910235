import React from 'react';
import { useTranslation } from 'react-i18next';
import {
    Stack,
    Checkbox,
    Typography,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Tooltip,
    Button,
    IconButton,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import DecimalInput from './TableBodyCells/DecimalInput';
import IntegerInput from './TableBodyCells/IntegerInput';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ProductionQuantityLimitsIcon from '@mui/icons-material/ProductionQuantityLimits';


export default function BankMobileBody(props) {

    const {
        rows,
        headCells,
        isSelected,
        handleSelectAllClick,
        handleQuantityChange,
        handleClick,
        showCurrentStatus,
        handleBankAccountClick,
        roundToTwoDecimals,
        roleId,
        emulation,
        mobile,
        studentStatId,
        setStudentStatId,
        handleStudentStatistics,
        renderAccruedForInput,
        handleAccruedForChange,
        handleOpenEditQuantityModal,
    handleOpenEditProductModal,
    onDeleteProductClick,
    hiddenRows,
    onVisibilityOfProductClick,
    } = props;

    const { t, i18n } = useTranslation();

    return (
        <>
            {rows.length && headCells.some(cell => cell.type === 'checkbox') ? (
                <Stack
                    direction="row"
                    alignItems="center"
                    spacing={2}
                    marginTop={'12px'}
                    marginBottom={'12px'}
                >
                    <Checkbox
                        color="primary"
                        onChange={handleSelectAllClick}
                        inputProps={{
                            'aria-label': 'select all',
                        }}
                    />
                    <Typography>{t('activeTransactions.selectAll')}</Typography>
                </Stack>
            ) : null}
            
            {rows.map((row, index) => {
                const isRowHidden = hiddenRows
                    ? hiddenRows.map((item) => item.id).includes(row.id)
                    : null;
                const isItemSelected = isSelected ? isSelected(row.rowId) : null;
                const labelId = `enhanced-table-checkbox-${index}`;
                let dataSetTitle = '';

                if (row.days_stat) {
                    for (let i in row.days_stat.dataSet) {
                        const d = new Date(i);
                        const _month =
                            d.getMonth() + 1 < 10
                                ? '0' + (d.getMonth() + 1)
                                : d.getMonth() + 1;
                        const _date =
                            d.getDate() < 10
                                ? '0' + d.getDate()
                                : d.getDate();
                        dataSetTitle += `${_date}.${_month}.${d.getFullYear()} - ${row.days_stat.dataSet[i]
                            }%\n`;
                    }
                }

                return (
                    <Accordion key={row.rowId}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            sx={{
                                bgcolor: '#e4f9e8 !important',
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                width: '100%',
                            }}
                        >
                            {row.surname} {row.name}
                            {row.productName ? (
                                <span>
                                    {row.productName}
                                    <span style={{ marginLeft: '20px' }}>{row.quantity} шт</span>
                                </span>
                            ) : ''}
                            {row.title ? (
                                <span>
                                    {row.title}
                                </span>
                            ) : ''}
                        </AccordionSummary>
                        <AccordionDetails>
                            <Stack
                                width='100%'
                                direction="column"
                                justifyContent="space-between"
                                alignItems="flex-start"
                                spacing={2}
                            >
                                {headCells.map((headCell) => {
                                    if (headCell.id === 'teacher' && !((roleId === 2 && emulation.length === 1) || (roleId === 3 && !emulation.length))) {
                                        return null;
                                    }

                                    if (headCell.type === 'displayOrder') {
                                        if (roleId === 3 && !emulation.length) {
                                            return (
                                                <Stack
                                                    sx={{ width: '100%' }}
                                                    key={headCell.id}
                                                    direction="row"
                                                    justifyContent="space-between"
                                                    alignItems="center"
                                                    spacing={2}
                                                >
                                                    <Typography>{t(headCell.label)}</Typography>
                                                    <Typography>{t(row.promo)}</Typography>
                                                    </Stack>
                                            );
                                        }
                                        return null;
                                    };

                                    if (headCell.type === 'options') {
                                        if (roleId === 3 && !emulation.length) {
                                            return (
                                                <Stack
                                                    sx={{ width: '100%' }}
                                                    key={headCell.id}
                                                    direction="row"
                                                    justifyContent="space-between"
                                                    alignItems="center"
                                                    spacing={2}
                                                >
                                                    <Typography>{t(headCell.label)}</Typography>
                                                    <Stack
                                                        direction="row"
                                                        spacing={2}
                                                        sx={{
                                                            mr: '10px',
                                                        }}
                                                    >
                                                        <Tooltip
                                                            title={t(
                                                                'storefrontComponent.editProductQuantity'
                                                            )}
                                                        >
                                                            <IconButton
                                                                onClick={() =>
                                                                    handleOpenEditQuantityModal(
                                                                        row.id
                                                                    )
                                                                }
                                                            >
                                                                <ProductionQuantityLimitsIcon />
                                                            </IconButton>
                                                        </Tooltip>
                                                        <Tooltip
                                                            title={t(
                                                                'storefrontComponent.editProduct'
                                                            )}
                                                        >
                                                            <IconButton
                                                                onClick={() =>
                                                                    handleOpenEditProductModal(
                                                                        row.id
                                                                    )
                                                                }
                                                            >
                                                                <EditIcon />
                                                            </IconButton>
                                                        </Tooltip>
                                                        <Tooltip
                                                            title={t(
                                                                'storefrontComponent.deleteProduct'
                                                            )}
                                                        >
                                                            <IconButton
                                                                onClick={() =>
                                                                    onDeleteProductClick(
                                                                        row.id
                                                                    )
                                                                }
                                                            >
                                                                <DeleteIcon />
                                                            </IconButton>
                                                        </Tooltip>
                                                        <Tooltip
                                                            title={
                                                                isRowHidden
                                                                    ? t(
                                                                        'storefrontComponent.productHidden'
                                                                    )
                                                                    : t(
                                                                        'storefrontComponent.visibleProduct'
                                                                    )
                                                            }
                                                        >
                                                            <IconButton
                                                                onClick={() =>
                                                                    onVisibilityOfProductClick(
                                                                        row.id
                                                                    )
                                                                }
                                                            >
                                                                {isRowHidden ? (
                                                                    <VisibilityOffIcon
                                                                        style={{
                                                                            color: 'red',
                                                                        }}
                                                                    />
                                                                ) : (
                                                                    <VisibilityIcon
                                                                        style={{
                                                                            color: 'green',
                                                                        }}
                                                                    />
                                                                )}
                                                            </IconButton>
                                                        </Tooltip>
                                                    </Stack>
                                                </Stack>
                                            );
                                        }
                                        return null;
                                    };

                                    return (
                                        <>
                                            {(headCell.type === 'checkbox') && (
                                                <Stack
                                                    direction="row"
                                                    justifyContent="space-between"
                                                    alignItems="center"
                                                    spacing={2}
                                                >
                                                    <Checkbox
                                                        color="primary"
                                                        onClick={(event) => handleClick(event, row.rowId)}
                                                        checked={isItemSelected}
                                                        inputProps={{
                                                            'aria-labelledby': labelId,
                                                        }}
                                                    />
                                                </Stack>
                                            )}
                                            <Stack
                                                sx={{ width: '100%' }}
                                                key={headCell.id}
                                                direction="row"
                                                justifyContent="space-between"
                                                alignItems="center"
                                                spacing={2}
                                            >
                                                <Typography>{t(headCell.label)}</Typography>
                                                {headCell.type === 'decimalInput' ? (
                                                    <DecimalInput
                                                        id={row.rowId}
                                                        quantity={row.quantity}
                                                        onChange={handleQuantityChange}
                                                        isSelected={isSelected}
                                                        t={t}
                                                        mobile={mobile}
                                                    />
                                                ) : headCell.type === 'integerInput' ? (
                                                    <IntegerInput
                                                        id={row.rowId}
                                                        quantity={row.quantity}
                                                        onChange={handleQuantityChange}
                                                        isSelected={isSelected}
                                                        t={t}
                                                        mobile={mobile}
                                                    />
                                                ) : headCell.type === 'status' ? (
                                                    showCurrentStatus(row.status, { t, i18n })
                                                ) : headCell.type === 'link' ? (
                                                    <Tooltip
                                                        title="SOROBANK"
                                                        placement="bottom"
                                                    >
                                                        <Button
                                                            onClick={() => handleBankAccountClick(row.rowId)}
                                                            style={{
                                                                height: '40px',
                                                                borderRadius: '20px',
                                                                fontSize: '14px',
                                                                color: '#58a315',
                                                            }}
                                                            aria-label="SOROBANK"
                                                            type="button"
                                                            startIcon={
                                                                <AccountBalanceIcon
                                                                    style={{ color: '#58a315' }}
                                                                />
                                                            }
                                                        >
                                                            SOROBANK
                                                        </Button>
                                                    </Tooltip>
                                                ) : headCell.type === 'group' ? (
                                                    <Typography>
                                                        {typeof row.group === 'object'
                                                            ? Object.values(row.group).find(value => value !== null && value !== undefined && value !== '') || ''
                                                            : row.group || ''}
                                                    </Typography>
                                                ) : headCell.type === 'image' ? (
                                                    <Stack
                                                        direction="column"
                                                        justifyContent="flex-end"
                                                        alignItems="flex-end"
                                                        spacing={2}
                                                    >
                                                        <img
                                                            width="50"
                                                            src={row.img}
                                                            alt={row.productName}
                                                        />
                                                        <Typography
                                                            width={200}
                                                            textAlign={'right'}
                                                            variant="body2"
                                                            color="text.secondary"
                                                        >
                                                            {row.productName}
                                                        </Typography>
                                                    </Stack>
                                                ) : headCell.type === 'img' ? (
                                                  
                                                    <img
                                                        src={row.img}
                                                        alt="Product Image"
                                                        style={{
                                                            width: '70px',
                                                            height: '70px',
                                                            borderRadius: '7px',
                                                            objectFit: 'cover',
                                                        }}
                                                    />
                                                   
                                                ) : headCell.type === 'sum' ? (
                                                    <Typography>
                                                        {`${roundToTwoDecimals(row.price * row.quantity)} SBK`}
                                                    </Typography>
                                                ) : headCell.type === 'statistics' ? (
                                                    <Button
                                                        onClick={() => {
                                                            setStudentStatId(
                                                                null
                                                            );
                                                            setTimeout(() => {
                                                                handleStudentStatistics(
                                                                    row.id,
                                                                    `${row.name} ${row.surname}`
                                                                );
                                                            }, 200);
                                                        }}
                                                        title={dataSetTitle}
                                                        color="inherit"
                                                        style={{
                                                            backgroundColor:
                                                                studentStatId &&
                                                                    studentStatId.id ===
                                                                    row.id
                                                                    ? '#00a10c'
                                                                    : 'inherit',
                                                            color:
                                                                studentStatId &&
                                                                    studentStatId.id ===
                                                                    row.id
                                                                    ? '#fff'
                                                                    : 'inherit',
                                                        }}
                                                        className="single_group_btn"
                                                        variant="contained"
                                                    >
                                                        {row.days_stat.value}
                                                    </Button>
                                                ) : headCell.type === 'comment' ? (
                                                    <Typography>
                                                        {renderAccruedForInput(
                                                            row.rowId,
                                                            row.accruedFor,
                                                            handleAccruedForChange
                                                        )}
                                                    </Typography>
                                                ) : headCell.type === 'amount' ? (
                                                    <Typography>
                                                        {row.amount || 0}
                                                    </Typography>
                                                ) : headCell.type === 'vip' ? (
                                                    <Typography>
                                                        {+row.price !== +row.vip ? row.vip : null}
                                                    </Typography>
                                                ) : (
                                                    <Typography align="right">{row[headCell.id]}</Typography>
                                                )}
                                            </Stack>
                                        </>
                                    );
                                })}
                            </Stack >
                        </AccordionDetails>
                    </Accordion>
                );
            })}
        </>
    );
};


