import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { TableCell, TableHead, TableRow, Typography, Checkbox } from '@mui/material';

export default function BankTableHead(props) {
    const { onSelectAllClick, numSelected, rowCount, headCells } = props;
    const { t } = useTranslation();
    const roleId = useSelector((state) => state.user.info.role_id);
    const emulation = useSelector((state) => state.user.emulation);

    return (
        <TableHead>
            <TableRow>
                {headCells.map((headCell) => {
                    if (headCell.type === 'checkbox') {
                        return (
                            <TableCell key={headCell.id} padding="checkbox">
                                <Checkbox
                                    color="primary"
                                    onChange={onSelectAllClick}
                                    inputProps={{ 'aria-label': 'select all items' }}
                                />
                            </TableCell>
                        );
                    }

                    if (headCell.type === 'options' || headCell.type === 'displayOrder') {
                        if (roleId === 3 && !emulation.length) {
                            return (
                                <TableCell
                                    key={headCell.id}
                                    align="left"
                                    padding={headCell.disablePadding ? 'none' : 'normal'}
                                >
                                    <Typography>{t(headCell.label)}</Typography>
                                </TableCell>
                            );
                        }
                        return null; 
                    }

                    if (
                        (roleId === 2 && emulation.length === 1) ||
                        (roleId === 3 && !emulation.length) ||
                        headCell.id !== 'teacher'
                    ) {
                        return (
                            <TableCell
                                key={headCell.id}
                                align="left"
                                padding={headCell.disablePadding ? 'none' : 'normal'}
                            >
                                <Typography>{t(headCell.label)}</Typography>
                            </TableCell>
                        );
                    }
                    return null;
                })}
            </TableRow>
        </TableHead>
    );
};


