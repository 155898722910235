import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import store from './../../store';
import { openRequestModal } from '../../actions/modals';
import { setProductsAllFranch } from '../../actions/bank';
import BankProductsService from '../../services/bank-products.service';
import { useTranslation } from 'react-i18next';
import usePhoneCheck from '../../hooks/usePhoneCheck';
import BankMobileBody from '../../components/ComponentTable/Table/BankMobileBody';
import BankTableBody from '../../components/ComponentTable/Table/BankTableBody';
import BankTableHead from '../../components/ComponentTable/Table/BankTableHead';

import {
    Box,
    Table,
    TableContainer,
    Paper,
    CircularProgress,
} from '@mui/material';


const headCells = [
    {
        id: 'promo',
        numeric: false,
        disablePadding: false,
        label: '№',
        align: 'left',
        type: 'displayOrder',
    },
    {
        id: 'img',
        numeric: false,
        disablePadding: false,
        label: 'storefrontComponent.image',
        align: 'left',
        type: 'img',
    },
    {
        id: 'title',
        numeric: false,
        disablePadding: false,
        label: 'storefrontComponent.productName',
        align: 'left',
        type: 'string',
    },
    {
        id: 'amount',
        numeric: true,
        disablePadding: false,
        label: 'storefrontComponent.quantity',
        align: 'left',
        type: 'amount',
    },
    {
        id: 'price',
        numeric: false,
        disablePadding: false,
        label: 'storefrontComponent.price',
        align: 'left',
        type: 'string',
    },
    {
        id: 'vip',
        numeric: false,
        disablePadding: false,
        label: 'VIP',
        align: 'left',
        type: 'vip',
    },    
    {
        id: 'options',
        numeric: false,
        disablePadding: false,
        label: 'storefrontComponent.options',
        align: 'right',
        type: 'options',
    },
];


export default function StorefrontComponent() {
    const [hiddenRows, setHiddenRows] = useState([]);
    const [rows, setRows] = useState([]);
    const [loading, setLoading] = useState(true);
    const roleId = useSelector((state) => state.user.info.role_id);
    const dispatch = useDispatch();
    const { emulation } = store.getState().user;
    const user = useSelector((state) => state.bank);
    const sortedRows = rows.slice().toSorted((a, b) => a.promo - b.promo);
    const { t } = useTranslation();
    const mobile = usePhoneCheck();


    // отримати всі товари франча
    useEffect(() => {
        const getProductsAllFranch = () => {
            if (roleId === 3 && !emulation.length) {
                const productsAllFranch = user.market;
                setRows(productsAllFranch);
            } else {
                // TODO: refactor this
                return;
            }
        };

        getProductsAllFranch();
        setLoading(false);
    }, [roleId, emulation, user.market]);

    const fetchProductsAllFranch = async () => {
        await dispatch(setProductsAllFranch());
    };

    useEffect(() => {
        const hiddenProducts = rows.filter((item) => item.hide);
        setHiddenRows(hiddenProducts);
    }, [rows]);

    // отримати лише активні товари товари
    const getActiveProducts = async () => {
        if (roleId === 3 && !emulation.length) {
            return;
        }
        
        const res = await BankProductsService.GetActiveProducts();

        const products = res
            ? res.map((item) => ({
                id: item.id,
                title: item.title,
                amount: item.amount || 0,
                price: item.price,
                vip: item.vip || null,
                img: item.img,
                promo: item.promo,
                detail: item.detail,
            }))
            : [];

        setRows(products);
        setLoading(false);
    };

    useEffect(() => {
        getActiveProducts();
    }, []);

    // При кліку на кнопку видалити
    function onDeleteProductClick(rowId) {
            dispatch(
                openRequestModal({
                    opened: true,
                    data: {
                        id: rowId, // id товара
                        header: t(
                            'storefrontComponent.titleModalRemoveProduct'
                        ),
                        modaltype: 'ConfirmRemoveProduct',
                    },
                })
            );
    }

    async function onVisibilityOfProductClick(rowId) {
            try {
                const res = await BankProductsService.GetProductById(rowId);
                const visible = res.hide;
                const data = {
                    id: Number(rowId),
                    hide: !visible,
                };
                await BankProductsService.HideProductById(data);

                await fetchProductsAllFranch();
            } catch (error) {
                console.error(error);
            }
    }

    const handleOpenEditProductModal = (rowId) => {
        dispatch(
            openRequestModal({
                opened: true,
                data: {
                    id: rowId,
                    header: t('storefrontComponent.titleModalEditProduct'),
                    modaltype: 'EditProduct',
                },
            })
        );
    };

    const handleOpenEditQuantityModal = (rowId) => {
        dispatch(
            openRequestModal({
                opened: true,
                data: {
                    id: rowId,
                    header: t(
                        'storefrontComponent.titleModalEditProductQuantity'
                    ),
                    modaltype: 'ЕditProductQuantity',
                },
            })
        );
    };

    if (loading) {
        return (
            <Paper elevation={0}>
                <Box m={2} p={2}>
                    <CircularProgress />
                </Box>
            </Paper>
        );
    }

    if (!loading) {
        return (
            <Box sx={{ width: '100%' }}>
                <Paper sx={{ width: '100%', mb: 2 }}>
                    {mobile ? (
                        <BankMobileBody
                            rows={sortedRows}
                            headCells={headCells}
                            roleId={roleId}
                            emulation={emulation}
                            handleOpenEditQuantityModal={handleOpenEditQuantityModal}
                            handleOpenEditProductModal={handleOpenEditProductModal}
                            onDeleteProductClick={onDeleteProductClick}
                            hiddenRows={hiddenRows}
                            onVisibilityOfProductClick={onVisibilityOfProductClick}
                            mobile={mobile}
                        />
                    ) : (
                        <TableContainer style={{ height: '69vh' }}>
                            <Table
                                stickyHeader
                                sx={{ minWidth: 750 }}
                                aria-labelledby="tableTitle"
                            >
                                <BankTableHead
                                    headCells={headCells}
                                />
                                <BankTableBody
                                    rows={sortedRows}
                                    headCells={headCells}
                                    roleId={roleId}
                                    emulation={emulation}
                                    handleOpenEditQuantityModal={handleOpenEditQuantityModal}
                                    handleOpenEditProductModal={handleOpenEditProductModal}
                                    onDeleteProductClick={onDeleteProductClick}
                                    hiddenRows={hiddenRows}
                                    onVisibilityOfProductClick={onVisibilityOfProductClick}
                                />
                            </Table>
                        </TableContainer>
                    )}
                </Paper>
            </Box>
        );
    }
}


