import React, { useState, useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { setCitiesBalances } from '../../actions/bank';
import BankTransactionsService from '../../services/bank-transactions.service';
import { useTranslation } from 'react-i18next';
import usePhoneCheck from '../../hooks/usePhoneCheck';
import BankMobileBody from '../../components/ComponentTable/Table/BankMobileBody';
import CustomAutocomplete from '../../components/CustomAutocomplete';
import BankTableBody from '../../components/ComponentTable/Table/BankTableBody';
import BankTableHead from '../../components/ComponentTable/Table/BankTableHead';
import BankTableToolbar from '../../components/ComponentTable/Table/BankTableToolbar'

import {
    Stack,
    Box,
    Table,
    TableContainer,
    Paper,
} from '@mui/material';
import { toast } from 'react-toastify';
import BackButton from '../../components/BalanceComponent/BackButton';

const headCells = [
    {
        id: 'checkbox', 
        numeric: false,
        disablePadding: false,
        label: '', 
        type: 'checkbox', 
    },
    {
        id: 'rowId',
        numeric: false,
        disablePadding: false,
        label: 'bankFranchPage.cityId',
        type: 'string',
    },
    {
        id: 'city',
        numeric: false,
        disablePadding: false,
        label: 'bankFranchPage.city',
        type: 'string',
    },
    {
        id: 'surname',
        numeric: false,
        disablePadding: false,
        label: 'bankFranchPage.surname',
        type: 'string',
    },
    {
        id: 'name',
        numeric: false,
        disablePadding: false,
        label: 'bankFranchPage.name',
        type: 'string',
    },
    {
        id: 'balance',
        numeric: false,
        disablePadding: false,
        label: 'bankFranchPage.balance',
        type: 'string',
    },
    {
        id: 'cityStatus',
        numeric: false,
        disablePadding: false,
        label: 'bankFranchPage.status',
        type: 'cityStatus',
    },
    {
        id: 'quantity',
        numeric: true,
        disablePadding: false,
        label: 'bankFranchPage.quantity',
        type: 'decimalInput',
    },
    {
        id: 'bankAccount',
        numeric: true,
        disablePadding: false,
        label: 'bankFranchPage.bankAccount',
        type: 'link',
    },

];


function getRowData(data) {

    return data
        ? data.map((item) => ({
              error: false,
              rowId: item.franch_id,
              cityId: item.city_id,
              name: item.franch_first_name || '',
              surname: item.franch_last_name || '',
              city: item.city_name.split("|||")[1] || '',
              balance: Number(item.franch_balance)+Number(item.teach_balance)+Number(item.student_balance),
              quantity: 300,
              cityStatus: "",
          }))
        : [];
}

export default function CityBalances() {
    const [selected, setSelected] = useState([]);
    const [franch, setFranch] = useState('');
    const [city, setCity] = useState('');
    const [cityStatus, setCityStatus] = useState('');
    const [loading, setLoading] = useState(false);
    const [errorStates, setErrorStates] = useState({});

    const mobile = usePhoneCheck();
    const history = useHistory();
    const { t } = useTranslation();
    const user = useSelector((state) => state.bank);
    const balance = user.customer.balance || 0;

    const citiesBalancesInfo = useMemo(() => {
        return getRowData(user.customer.citiesBalances);
    }, [user]);

    useEffect(() => {
        setRows(citiesBalancesInfo);
    }, [citiesBalancesInfo]);

    const [rows, setRows] = useState(citiesBalancesInfo);

    const dispatch = useDispatch();

    const fetchCitiesBalances = async () => {
        await dispatch(setCitiesBalances());
    };

    useEffect(() => {
        fetchCitiesBalances();
    }, []);

    const handleQuantityChange = (id, value) => {
        // Знаходимо індекс рядка з вказаним id
        const rowIndex = rows.findIndex((row) => row.rowId === id);
        // Створюємо новий екземпляр масиву rows, щоб уникнути мутації стану безпосередньо
        const newRows = [...rows];
        // Оновлюємо значення 'quantity' для конкретного рядка
        newRows[rowIndex] = {
            ...newRows[rowIndex],
            quantity: parseFloat(value) || '',
        };
        // Оновлюємо значення 'quantity' для конкретного рядка
        setRows(newRows);

        // Оновлюємо стан помилок
        setErrorStates((prevState) => ({
            ...prevState,
            [id]: value === '',
        }));
    };

    const handleSelectAllClick = (event) => {
        if (loading) return;
        if (event.target.checked) {
            const newSelected = rows.map((n) => n.rowId);
            setSelected(newSelected);
            return;
        }
        setSelected([]);
    };

    const handleClick = (event, id) => {
        if (loading) return;

        const selectedIndex = selected.indexOf(id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = [...selected, id];
        } else if (selectedIndex >= 0) {
            newSelected = selected.filter((itemId) => itemId !== id);
        }

        setSelected(newSelected);

        setErrorStates((prevState) => ({
            ...prevState,
            [id]: false, // Очищення стану помилки при кліку на чекбокс
        }));
    };

    const isSelected = (id) => selected.indexOf(id) !== -1;

    async function handleSuccessClick() {

        // Оберемо транзакції з 0 баланс, якщо такі є виходимо з функції
        const hasEmptyQuantity = rows
            .filter((row) => isSelected(row.rowId))
            .some(row => !row.quantity);

        if (hasEmptyQuantity) {
            toast.error(t('bankStudentsPage.hasEmptyQuantity'));
            return;
        };

    // Відфільтровуємо транзакції для підтвердження
        const data = rows
            .filter((row) => isSelected(row.rowId))
            .map((el) => ({
                amount: Number(el.quantity),
                customerId: Number(el.rowId), 
                note: '',
            }));

        const totalAmount = data.reduce((accumulator, user) => accumulator + user.amount, 0)
        if (totalAmount <= balance) {
            await Promise.all(
                data.map(async (item) => {
                    try {
                        await BankTransactionsService.TransactionById(item)
 
                    } catch (error) {
                        console.log(error);
                    }
                })
            ); 
        } else {
            toast.error(t('sorokoinsForHomework.notEnoughMoney'));
        }
         setSelected([]);
    }

    // Фільтрація
    const handleFilterChange = (newFranch, newCity, newStatus) => {
        if (loading) return;

        let filteredRows = citiesBalancesInfo;

        if (newFranch) {
            filteredRows = filteredRows.filter((row) => row.surname === newFranch);
        }
        if (newCity) {
            filteredRows = filteredRows.filter((row) => row.city === newCity);
        }
        if (newStatus) {
            filteredRows = filteredRows.filter((row) => row.cityStatus === newStatus);
        }

        setRows(filteredRows);
    };

    const handleBankAccountClick = (id) => {
        history.push(`/users/bank/${id}/franch`);
    };


    return (
        <Box sx={{ width: '100%' }}>
            <BackButton />
            <Paper sx={{ width: '100%', mb: 2, p: 3 }}>
                <Stack direction={mobile ? "column" : "row"} justifyContent="flex-start" spacing={2}>
                    <CustomAutocomplete
                        label={t('bankFranchPage.surname')}
                        options={rows
                            .map((option) => option.surname)
                            .filter(
                                (el, index, arr) => arr.indexOf(el) === index
                            )}
                        onChange={(event, newFranch) => handleFilterChange(newFranch, city, cityStatus)}
                        inputValue={franch}
                        onInputChange={(event, newInputValue) => {
                            setFranch(newInputValue);
                        }}
                        sx={{ width: 260, marginBottom: '10px' }}
                    />
                    <CustomAutocomplete
                        label={t('bankFranchPage.city')}
                        options={rows
                            .map((option) => option.city)
                            .filter(
                                (el, index, arr) => arr.indexOf(el) === index
                            )}
                        onChange={(event, newCity) => handleFilterChange(franch, newCity, cityStatus)}
                        inputValue={city}
                        onInputChange={(event, newInputValue) => {
                            setCity(newInputValue);
                        }}
                    />
                    <CustomAutocomplete
                        label={t('bankFranchPage.status')}
                        options={rows
                            .map((option) => option.cityStatus)
                            .filter(
                                (el, index, arr) => arr.indexOf(el) === index
                            )}
                        onChange={(event, newStatus) => handleFilterChange(franch, city, newStatus)}
                        inputValue={cityStatus}
                        onInputChange={(event, newInputValue) => {
                            setCityStatus(newInputValue);
                        }}
                    />
                </Stack>
                <BankTableToolbar
                    title={t('bankFranchPage.selected')}
                    titleTooltipSuccess={t('bankStudentsPage.tooltipTitle')}
                    numSelected={selected.length}
                    onSuccessClick={handleSuccessClick}
                    loading={loading}
                    selectedUserIds={selected}
                    rows={rows}
                />
                {mobile ? (
                    <BankMobileBody
                        rows={rows}
                        headCells={headCells}
                        isSelected={isSelected}
                        handleSelectAllClick={handleSelectAllClick}
                        handleQuantityChange={handleQuantityChange}
                        handleClick={handleClick}
                        handleBankAccountClick={handleBankAccountClick}
                        mobile={mobile}
                    />
                ) : (
                    <TableContainer style={{ maxHeight: '59vh' }}>
                        <Table
                            stickyHeader
                            sx={{ minWidth: 750 }}
                            aria-labelledby="tableTitle"
                        >
                            <BankTableHead
                                numSelected={selected.length}
                                onSelectAllClick={handleSelectAllClick}
                                headCells={headCells}
                            />
                            <BankTableBody
                                rows={rows}
                                headCells={headCells}
                                isSelected={isSelected}
                                handleClick={handleClick}
                                handleQuantityChange={handleQuantityChange}
                                handleBankAccountClick={handleBankAccountClick}
                            />
                        </Table>
                    </TableContainer>
                )}
            </Paper>
        </Box>
    );
}

